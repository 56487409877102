import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { Observable, Subject, tap } from 'rxjs';
import { MessagesCount } from '@shared/models/messages-count.model';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessagesCounterApiService extends ApiService<any> {
  public messagesCount$: Subject<number> = new Subject<number>();
  protected endpoint = 'inbox';

  public getMessagesCount(): Observable<MessagesCount> {
    return this.apiHttp.get<{ data: number[] }>(`/${this.endpoint}/unread`).pipe(
      tap((count) => {
        return this.messagesCount$.next(count.data[0]);
      }),
    );
  }

  public updateMessagesCount(actionId: string): void {
    this.apiHttp
      .put(`/${this.endpoint}/read/${actionId}`, '')
      .pipe(first())
      .subscribe((count: { data: number[] }) => {
        this.messagesCount$.next(count.data[0]);
      });
  }
}
